import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Page, Button, ButtonsGroup, Wrapper, Label, Upload } from '../../../components/ui';
import { InputTextField } from '../../../components/ui/input';
import { CampaignsService } from '../../../services';

import API from '../../../config/api';
import TEXT from '../../../config/text';

class Page__CampaignsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      src: null,
      campaignUrl: '',
      campaignName: '',
      areas: [],
      tempArea: {},
      isReadyToDraw: false,
      isDrawing: false,
      htmlContent: '',
      fileUrl: '',
      imageData: '',
      editMode: false,
    };
    this.tempDrawingRef = createRef();
  }

  variants = {
    facebook: { title: 'Facebook', href: 'https://www.facebook.com/Definitlaser', shape: 'rectangle' },
    instagram: { title: 'Instagram', href: 'https://www.instagram.com/definit_ar/', shape: 'rectangle' },
    mail: { title: 'Mail', href: 'mailto:consultas@definit.com.ar', shape: 'rectangle' }
  }


  /**
* @Event
* @Description: Is invoked immediately after a component is mounted.
*/
  componentDidMount() {
    this._Init();
  };

  componentDidUpdate(prevProps, prevState) {
    // Check if any relevant state has changed
    if (
      prevState.src !== this.state.src ||
      prevState.campaignUrl !== this.state.campaignUrl ||
      prevState.campaignName !== this.state.campaignName ||
      prevState.areas !== this.state.areas
    ) {
      // Call generateHtmlContent to update htmlContent and fileUrl state
      this.generateHtmlContent();
    }
  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    const id = this.props.match.params.id;

    if (id) {
      this._Find(id);
    }
  };

  /*
** @Service
** @Description: Find Featured
*/

  _Find = (id) => {

    // @Service
    CampaignsService.find(id).then((response) => {
      // @State
      // @Description: Set note update into state
      this.setState({
        loading: false,
        id: response.campaign._id,
        name: response.campaign.name,
        src: API.endpoint.uploads + response.campaign.campaign_image.path,
        campaignUrl: response.campaign.campaign_link,
        campaignName: response.campaign.name,
        areas: JSON.parse(response.campaign.areas_map),
        htmlContent: response.campaign.html,
        imageData: response.campaign.campaign_image,
        editMode: true
      });


    }).catch((error) => {

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data ? error.data.message : TEXT.message.error
      });

    });

  };


  onSelectFile = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ src: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      console.error("File is not an image");
    }
  };

  onUploadImagen = (files) => {
    if (files.length > 0) {
      this.setState({ src: API.endpoint.uploads + files[0].path });
      this.setState({ imageData: files[0] });
    }
  };


  setShapeAndDrawMode = (shape, variant) => {
    if (variant) {
      this.setState(prevState => {
        return {
          tempArea: { ...prevState.tempArea, shape: this.variants[variant].shape, title: this.variants[variant].title, href: this.variants[variant].href },
          isReadyToDraw: true,
          isDrawing: false
        }
      });
    }
    else {
      this.setState(prevState => {
        return {
          tempArea: { ...prevState.tempArea, shape: shape },
          isReadyToDraw: true,
          isDrawing: false
        }
      });
    }
  };

  startDrawing = (e) => {
    if (!this.state.isReadyToDraw) return;
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    this.tempX2 = x;
    this.tempY2 = y;
    this.setState(prevState => {
      return {
        tempArea: { ...prevState.tempArea, x1: x, y1: y, x2: x, y2: y },
        isDrawing: true,
      }
    }, () => {
      this.updateVisualFeedback(this.state.tempArea);
    });
  };

  continueDrawing = (e) => {
    if (!this.state.isDrawing || !this.tempDrawingRef.current) return;
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    this.tempX2 = x;
    this.tempY2 = y;

    this.updateVisualFeedback({ ...this.state.tempArea, x2: this.tempX2, y2: this.tempY2 });
  };

  updateVisualFeedback = (area) => {
    if (!this.tempDrawingRef.current) return;

    const style = this.calculateAreaStyle(area, area.shape);
    for (const property in style) {
      this.tempDrawingRef.current.style[property] = style[property];
    }
  };

  endDrawing = () => {
    if (!this.state.isDrawing) return;
    this.setState(prevState => {
      return {
        tempArea: { ...prevState.tempArea, x2: this.tempX2, y2: this.tempY2 },
        isDrawing: false,
      }
    });
  };

  saveArea = () => {
    const { tempArea } = this.state;
    const hasDrawnValidArea = tempArea.x2 !== tempArea.x1 || tempArea.y2 !== tempArea.y1;

    if (!tempArea.title || !tempArea.href) {
      alert("Please fill in both title and href");
      return;
    }

    if (!hasDrawnValidArea) {
      alert("Please draw an area before saving");
      return;
    }

    // Proceed to save the area
    this.setState(prevState => ({
      areas: [...prevState.areas, tempArea],
      tempArea: {},
      isReadyToDraw: false,
    }));
  };


  deleteArea = (indexToDelete) => {
    this.setState(prevState => ({
      areas: prevState.areas.filter((_, index) => index !== indexToDelete)
    }));
  };

  renderAreas = () => {
    return this.state.areas.map((area, index) => (
      <div
        key={index}
        style={this.calculateAreaStyle(area, area.shape)}
      >
        <div>
          {(!this.state.isDrawing &&
            <button
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                padding: '2px 5px',
                cursor: 'pointer',
                background: 'red',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                fontSize: '12px',
                lineHeight: '12px',
                width: '20px',
                height: '20px',
                textAlign: 'center',
              }}
              onClick={() => this.deleteArea(index)}
              title="Delete area"
            >
              &#x2715;
            </button>
          )}
        </div>
      </div>
    ));
  };

  calculateAreaStyle = (area, shape) => {
    const style = {
      position: 'absolute',
      left: `${Math.min(area.x1, area.x2)}px`,
      top: `${Math.min(area.y1, area.y2)}px`,
      width: `${Math.abs(area.x2 - area.x1)}px`,
      height: `${Math.abs(area.y2 - area.y1)}px`,
      border: '2px solid red',
      PointerEvents: 'auto'

    };
    if (shape === 'circle') {
      const diameter = Math.max(Math.abs(area.x2 - area.x1), Math.abs(area.y2 - area.y1));
      style.width = style.height = `${diameter}px`;
      style.borderRadius = '50%';
    }
    return style;
  };

  generateHtmlContent = () => {
    const { src, areas, campaignUrl, campaignName } = this.state;
    if (!src || !campaignUrl) return ''; // Return an empty string if no image is uploaded or no campaignUrl is set
  
    const image = new Image();
    image.src = src;
  
    image.onload = () => {
      const naturalWidth = image.naturalWidth;
      const naturalHeight = image.naturalHeight;
  
      let htmlContent = `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>${campaignName}</title>
          <style>
              .image-container {
                  position: relative;
                  display: inline-block;
              }
              .image-container a.big-link {
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  z-index: 1;
                  border: '2px dashed blue'; 
              }
              .image-container a.small-link {
                  position: absolute;
                  display: block;
                  color: transparent;
                  z-index: 2; 
                  border: '2px dashed blue';
              }
              .image-container img {
                  display: block; 
                  height: auto;
                  width: 100%; 
              }
          </style>
      </head>
      <body>
      <center>
          <p style="font-size:11px;font-family:Arial,Helvetica,Sans-serif;margin:0;padding:10px 0;text-align:center;">
              Si no puedes visualizar correctamente el contenido <a href="${campaignUrl}" target="_blank" title="clic aquí">clic aquí</a>.
          </p>
          <div class="image-container">
              <a href="${campaignUrl}" class="big-link" target="_blank"></a>
              <img src="${src}" alt="Mapped Image" />
              ${areas.map((area) => {
                const x1Percent = (area.x1 / naturalWidth) * 100;
                const y1Percent = (area.y1 / naturalHeight) * 100;
                const widthPercent = ((area.x2 - area.x1) / naturalWidth) * 100;
                const heightPercent = ((area.y2 - area.y1) / naturalHeight) * 100;
                return `<a href="${area.href}" target="_blank" class="small-link" style="left: ${x1Percent}%; top: ${y1Percent}%; width: ${widthPercent}%; height: ${heightPercent}%;"></a>`;
              }).join('')}
          </div>
      </center>
      </body>
      </html>`;
  
      const blob = new Blob([htmlContent], { type: 'text/html' });
      const fileUrl = URL.createObjectURL(blob);
      this.setState({ htmlContent, fileUrl });
    };
  };


  _Save = () => {
    CampaignsService.save({
      name: this.state.campaignName,
      campaign_link: this.state.campaignUrl,
      areas_map: JSON.stringify(this.state.areas),
      campaign_image: this.state.imageData,
      html: this.state.htmlContent
    }).then((response) => {

      // @Dispatch alert success
      this.props.alertShow({
        open: true,
        severity: 'success',
        message: 'Campaña creada correctamente.'
      });

      // @Redirect
      this.props.history.push('/campaigns');

    }).catch((error) => {

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data ? error.data : TEXT.message.error
      });

    });
  }

  _Update = () => {
    const values = {
      name: this.state.campaignName,
      campaign_link: this.state.campaignUrl,
      areas_map: JSON.stringify(this.state.areas),
      campaign_image: this.state.imageData,
      html: this.state.htmlContent
    }
    CampaignsService.update(this.state.id, values).then((response) => {

      // @Dispatch alert success
      this.props.alertShow({
        open: true,
        severity: 'success',
        message: 'Campaña actualizada correctamente.'
      });

      // @Redirect
      this.props.history.push('/campaigns');

    }).catch((error) => {

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data ? error.data : TEXT.message.error
      });

    });
  }

  /*
 ** @Submit
 ** @Description: Submit form
 */
  handleSubmitForm = (values) => {
    if (this.state.id) {
      this._Update(this.state.id, values);
    } else {
      this._Save(values);
    }
  };


  render() {
    const { src, isReadyToDraw, isDrawing, campaignUrl, campaignName, tempArea, htmlContent } = this.state;
    return (
      <Page>
        {!src && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            <Upload
              label="Cargar imagen para la campaña"
              attachments={src ? [JSON.parse(src)] : []}
              multiple={false}
              avatar={true}
              paper={true}
              required
              onSuccess={this.onUploadImagen.bind(this)}
            />
          </div>
        )}
        <form noValidate autoComplete="off" onSubmit={this.handleSubmitForm} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: 'auto', maxWidth: '100%' }}>
            <div style={{ marginTop: '15px', marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {src && (
                <>
                  <Wrapper>
                    <Label>Predefinidos</Label>
                    <ButtonsGroup>
                      <Button onClick={() => this.setShapeAndDrawMode('rectangle', 'mail')}>Mail</Button>
                      <Button onClick={() => this.setShapeAndDrawMode('rectangle', 'facebook')}>Facebook</Button>
                      <Button onClick={() => this.setShapeAndDrawMode('rectangle', 'instagram')}>Instagram</Button>
                    </ButtonsGroup>
                  </Wrapper>
                  <Wrapper>
                    <Label>Custom</Label>
                    <ButtonsGroup>
                      <Button onClick={() => this.setShapeAndDrawMode('rectangle')}>Custom Link</Button>
                    </ButtonsGroup>
                  </Wrapper>
                  <Wrapper>
                    <ButtonsGroup>
                      {this.state.editMode ?
                        <Button type="submit">Guardar Cambios</Button>
                        : <Button type="submit">Guardar</Button>
                      }
                      <Button onClick={() => this.setState({ src: null })}>Cambiar Imagen</Button>
                    </ButtonsGroup>
                  </Wrapper>
                  <ButtonsGroup align='right'>
                    <Button component={NavLink} to={'/campaigns/'}>Volver</Button>
                    </ButtonsGroup>
                </>
              )}

              {(isReadyToDraw || isDrawing) && (
                <div style={{ marginTop: '10px' }}>
                  <InputTextField
                    type="text"
                    placeholder="Title"
                    value={tempArea.title}
                    onChange={e => this.setState({ tempArea: { ...tempArea, title: e.target.value } })}
                  />
                  <InputTextField
                    type="url"
                    placeholder="URL"
                    value={tempArea.href}
                    onChange={e => this.setState({ tempArea: { ...tempArea, href: e.target.value } })}
                  />
                  <ButtonsGroup>
                    <Button onClick={this.saveArea}>Agregar</Button>
                    <Button onClick={() => this.setState({ tempArea: {}, isReadyToDraw: false })}>Eliminar</Button>
                  </ButtonsGroup>
                </div>
              )}
            </div>
            {src && (
              <div style={{ marginTop: '15px' }}>
                <InputTextField
                  type="string"
                  placeholder="Nombre de la Campaña"
                  value={campaignName}
                  onChange={e => this.setState({ campaignName: e.target.value })}
                />
                <InputTextField
                  type="url"
                  placeholder="URL de la campaña"
                  value={campaignUrl}
                  onChange={e => this.setState({ campaignUrl: e.target.value })}
                />
                <div
                  style={{ position: 'relative', cursor: isReadyToDraw ? 'crosshair' : 'default' }}
                  onMouseDown={isReadyToDraw ? this.startDrawing : null}
                  onMouseMove={isDrawing ? this.continueDrawing : null}
                  onMouseUp={isDrawing ? this.endDrawing : null}
                >
                  <img src={src} alt="Uploaded" style={{ maxWidth: '100%', height: 'auto' }} draggable={false} />
                  {this.renderAreas()}
                  {(isDrawing || isReadyToDraw) && (
                    <>
                      <div ref={this.tempDrawingRef} style={{ position: 'absolute', border: '2px dashed blue' }}></div>
                      <div style={{ ...this.calculateAreaStyle(tempArea, tempArea.shape), border: '2px dashed blue' }}></div>
                    </>
                  )}
                </div>
              </div>
            )}
            {src && (
              <div style={{ marginTop: '15px', marginLeft: '20px' }}>
                <textarea
                  readOnly
                  value={htmlContent}
                  style={{ marginBottom: '10px', height: '600px', width: '400px' }}
                />
                <div style={{ textAlign: 'right' }}>
                  <ButtonsGroup align='right'>
                    {/* Download Button */}
                    {this.state.fileUrl && (
                      <div>
                        <a href={this.state.fileUrl} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
                          <Button>Previsualizar</Button>
                        </a>
                        <a href={this.state.fileUrl} download="index.html" style={{ marginLeft: '10px' }}>
                          <Button>Descargar HTML</Button>
                        </a>
                      </div>
                    )}
                  </ButtonsGroup>
                </div>
              </div>
            )}
          </div>
        </form>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default (connect(mapStateToProps, mapDispatchToProps)(Page__CampaignsForm));
